<template>
  <div class="box">
    <my-header></my-header>
    <div class="title">申请加入经销商记录</div>
    <div class="content">
      <el-table
        :data="ApplicationList"
        v-if="ApplicationList"
        height="250"
        border
        style="width: 100%"
      >
        <el-table-column prop="updateTime" label="日期" width="180">
        </el-table-column>
        <el-table-column label="经销商名称" width="160">
          <template slot-scope="scope">
            <span>{{ scope.row.companyVO.companyName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="经销商电话" width="160">
          <template slot-scope="scope">
            <span>{{ scope.row.companyVO.companyPhone }}</span>
          </template>
        </el-table-column>
        <el-table-column label="经销商状态" width="150">
          <template slot-scope="scope">
            <span
              :style="{
                color:
                  scope.row.companyVO.status == 0
                    ? 'green'
                    : scope.companyVO.row.status == 1
                    ? 'red'
                    : '',
              }"
              >{{ scope.row.companyVO.status | companyVOstatus }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="message" label="申请备注" width="500">
        </el-table-column>
        <el-table-column label="进度">
          <template slot-scope="scope">
            <span
              :style="{
                color:
                  scope.row.status == 0
                    ? '#666'
                    : scope.row.status == 1
                    ? 'green'
                    : scope.row.status == 2
                    ? 'red'
                    : '',
              }"
              >{{ scope.row.status | status }}</span
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ApplicationList: "",
    };
  },
  methods: {
    async getApplication() {
      const res = await this.$http({
        method: "get",
        url: "front/company/checkCompanyJoin/getApplication",
      });
      if (res.data.status == 200) {
        console.log(res.data.data.list);
        this.ApplicationList = res.data.data.list;
      }
    },
  },
  filters: {
    companyVOstatus(val) {
      if (val == 0) {
        return "正常";
      } else if (val == 1) {
        return "冻结";
      }
    },
    status(val) {
      if (val == 0) {
        return "未审核";
      } else if (val == 1) {
        return "已通过";
      } else if (val == 2) {
        return "已拒绝";
      }
    },
  },
  created() {
    this.getApplication();
  },
};
</script>

<style lang="scss" scoped>
.box {
  margin-top: 130px;
  .title {
    background: #f5f5f6;
    height: 150px;
    margin: 0 auto;
    font-size: 36px;
    color: #101d37;
    font-weight: 700;
    text-align: center;
    line-height: 120px;
    position: relative;
    color: #101d37 !important;
  }
  .content {
    width: 85%;
    margin: 0 auto;
  }
}
</style>