var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('my-header'),_c('div',{staticClass:"title"},[_vm._v("申请加入经销商记录")]),_c('div',{staticClass:"content"},[(_vm.ApplicationList)?_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.ApplicationList,"height":"250","border":""}},[_c('el-table-column',{attrs:{"prop":"updateTime","label":"日期","width":"180"}}),_c('el-table-column',{attrs:{"label":"经销商名称","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.companyVO.companyName))])]}}],null,false,2963365654)}),_c('el-table-column',{attrs:{"label":"经销商电话","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.companyVO.companyPhone))])]}}],null,false,776930861)}),_c('el-table-column',{attrs:{"label":"经销商状态","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{style:({
              color:
                scope.row.companyVO.status == 0
                  ? 'green'
                  : scope.companyVO.row.status == 1
                  ? 'red'
                  : '',
            })},[_vm._v(_vm._s(_vm._f("companyVOstatus")(scope.row.companyVO.status)))])]}}],null,false,2646271897)}),_c('el-table-column',{attrs:{"prop":"message","label":"申请备注","width":"500"}}),_c('el-table-column',{attrs:{"label":"进度"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{style:({
              color:
                scope.row.status == 0
                  ? '#666'
                  : scope.row.status == 1
                  ? 'green'
                  : scope.row.status == 2
                  ? 'red'
                  : '',
            })},[_vm._v(_vm._s(_vm._f("status")(scope.row.status)))])]}}],null,false,1337680801)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }